export const pr3_section1 = [
    <h2>Overview</h2>,
    <p>
        <br/>
        The aim of this project is to develop a mobile Power App, "Expense Tracker," for the tracking of business-related expenses incurred by employees.
        <br/><br/>
        A SharePoint list was used as the data source to store and manage expense records.
        <br/><br/>
        Power Apps was utilized to create an intuitive and user-friendly mobile interface for adding, viewing, and categorizing expenses.
        <br/><br/>
        The final application enables users to track spending patterns, categorize expenses, and gain insights into their financial habits, facilitating better budgeting and financial decision-making.
    </p>,
    <img src={"/assets/projects/content/et/et-overview.png"} alt="BI cycle" loading="lazy"></img>,
    <h3>Keywords</h3>,
    <p className='keywords'>
        <span className='keyword'>Power Platform</span>
        <span className='keyword'>Power Apps</span>
        <span className='keyword'>Microsoft tecnologies</span>
        <span className='keyword'>SharePoint</span>
        <span className='keyword'>Business Solution</span>
        <span className='keyword'>Business Intelligence</span>
        <span className='keyword'>Data visualization</span>
        <span className='keyword'>UI/UX</span>
    </p>
];

export const pr3_section2 = [
    <h2>Database model</h2>,
    <p>
        <br/>
        Below is the database model implemented using a SharePoint list.
    </p>,
    <img src={"/assets/projects/content/et/et-db.png"} alt="BI cycle" loading="lazy"></img>,
]

export const pr3_section3 = [
    <h2>App structure</h2>,
    <p>
        <br/>
        There are three main screens, each designed to enhance the user experience by offering clear insights into financial activities. 
        <br/><br/>
    </p>,
    <img src={"/assets/projects/content/et/et-app-structure.png"} alt="BI cycle" loading="lazy"></img>,
    <p>
        <br/><br/>
        The bottom navigation bar allows easy access to key app features, and a floating "Add Expense" button facilitates quick expense logging.
        <br/><br/>
        The Home screen serves as the dashboard, displaying an overview of the user's financial data. 
        It includes a total expenses summary, a line chart illustrating spending trends, and a savings plan section categorizing budget areas. 
        A recent transactions list provides a quick glance at the latest expenses with relevant details. 
        <br/><br/>
        The Expenses screen focuses on transaction history, listing all recorded expenses with details. 
        Transactions are color-coded and categorized, enabling users to easily distinguish different 
        spending types. 
        <br/><br/>
        The Add Expense screen allows users to input new expenses. 
        It includes fields for description, date, category selection, and amount 
        entry with currency options. A prominent Submit button ensures quick and easy data submition. 
    </p>, 
]

export const pr3_section4 = [
    <h2>Conclusion</h2>,
    <p>
        <br/><br/>
        The Expense Tracker Power App successfully streamlines business-related expense tracking for employees, offering an intuitive and efficient solution for financial management.
        <br/><br/>
        By integrating a well-structured SharePoint list as the database, the app ensures seamless data storage and retrieval.
    </p>,
]