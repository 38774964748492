import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import AboutPage from "../about-page/AboutPage";
import GPTTrialMatchProjectPage from "../projects-page/GPTTrialMatchProjectPage";
import SleepTrackerProjectPage from "../projects-page/SleepTrackerProjectPage";
import ExpenseTrackerProjectPage from "../projects-page/ExpenseTrackerProjectPage";
import InvIndexProjectPage from "../projects-page/InvIndexProjectPage";
import KTSEatsPage from "../projects-page/KTSEatsPage"
import "./MainPage.css"
import Footer from "../../components/footer/Footer";
import ProjectsLeftSideBar from "../../components/projects-left-sidebar/ProjectsLeftSideBar";
import SalesManPage from "../projects-page/SalesManagementPage";


const MainPage = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
      <Router>
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<AboutPage />} />
            <Route path="/projects" element={<ProjectLayout />}>
              <Route index element={<GPTTrialMatchProjectPage />} />
              <Route path=":projectKey" element={<ProjectDetail />} />
            </Route>
          </Routes>
        </main>
      </Router>
    );
};

const ProjectLayout = () => {
    return (
        <div>
            <div className="layout-container">
                <div className="project-main">
                    <ProjectsLeftSideBar />
                </div>
                <div className="project-details">
                    <Outlet />
                </div>
            </div>
        <Footer></Footer>
        </div>
    );
};

const ProjectDetail = () => {
  const { projectKey } = useParams();

    switch (projectKey) {
        case 'gpt-trial-match':
        return <GPTTrialMatchProjectPage />;
        case 'absence-manager':
        return <SleepTrackerProjectPage />;
        case 'sales-management':
        return <SalesManPage />;
        case 'inverted-index':
        return <InvIndexProjectPage />;
        case 'expense-tracker':
        return <ExpenseTrackerProjectPage />;
        case 'kts-eats':
        return <KTSEatsPage />;
        default:
        return <Navigate to="/projects/gpt-trial-match" />; 
    }
};

export default MainPage;