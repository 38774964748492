export const stOverviewSection = [
    <h2>Overview</h2>,
    <p>
        <br/>
        The aim of this project is to develop a responsive Power App, "Absence Manager" to streamline leave management processes.
        <br/><br/>
        Power Apps was used to create a user-friendly interface for requesting and approving absences.
        <br/><br/>
        SharePoint list was used as the data source to store and manage absence data.
        <br/><br/>
        Power Automate was implemented to automate workflows, enabling seamless interaction with Outlook and Teams for approval requests.
        <br/><br/>
        The final solution provides a structured and automated approach for managing employee absences, reducing manual effort and ensuring transparency in leave approvals.
        </p>,
    <img src={"/assets/projects/content/am/am-main.png"} alt="System components" loading="lazy"></img>,
     <h3>Keywords</h3>,
     <p className='keywords'>
        <span className='keyword'>Power Platform</span>
        <span className='keyword'>Power Apps</span>
        <span className='keyword'>Power Automate</span>
        <span className='keyword'>Microsoft tecnologies</span>
        <span className='keyword'>SharePoint</span>
        <span className='keyword'>Business Solution</span>
        <span className='keyword'>Business Intelligence</span>
        <span className='keyword'>Data visualization</span>
        <span className='keyword'>UI/UX</span>
        <span className='keyword'>Responsive design</span>
     </p>
];

export const stPlanningSection = [
    <h2>Database model</h2>,
    <p>
        <br/>
        Below is the database model implemented using a SharePoint list.
    </p>,
    <img src={"/assets/projects/content/am/am-dm.png"} alt="System components" loading="lazy"></img>,
   
]

export const stDesignSection = [
    <h2>Power Automate workflow</h2>,
    <p>
        <br/>
        Developed using Power Automate workflow for sending and approving requests using Outlook and Teams is presented below. 
    </p>,
    <img src={"/assets/projects/content/am/am-pa.png"} alt="System components" loading="lazy"></img>,
    <p>
        <br/>
        Created workflow is used to send requests to Outlook and Teams when the user clicks the submit button on the send request screen.
    </p>, 
]

export const stDevelopmentSection = [
    <h2>App Structure</h2>,
    <p>
        <br/>
        The sidebar navigation allows easy navigation through three main screens, each designed to enhance the user experience by offering clear insights into absence management.
        <br/><br/>
        The Home screen serves as the dashboard, displaying an overview of the user's request data. 
        It includes three widgets illustrating the main trends, as well as two sections: 
        one containing the user's recent pending requests and another for the user's requests to approve.
    </p>,
    <img src={"/assets/projects/content/am/am-hs.png"} alt="System components" loading="lazy"></img>,
    <p>
        <br/>
        The Send Request screen allows users to submit new requests. 
        It includes fields for dates, absence type selection, approver and deputy selection 
        (if the chosen absence type requires it), and comments. 
        A prominent Submit button ensures quick and easy data submission.
    </p>,
    <img src={"/assets/projects/content/am/am-sr.png"} alt="System components" loading="lazy"></img>,
    <p>
        <br/>
        The Approve Request screen focuses on approving requests, 
        listing all requests with details and providing the opportunity 
        to make a final decision supported by a comment.
    </p>,
    <img src={"/assets/projects/content/am/am-ar.png"} alt="System components" loading="lazy"></img>,

]

export const stConclusionsSection = [
    <h2 key="1">Conclusion</h2>,
    <p key="2">
        <br/>
        The "Absence Manager" Power App successfully addresses the challenges of leave management 
        by providing a streamlined, user-friendly interface that enhances the overall efficiency 
        of absence tracking and approval processes. 
        <br/><br/>
        By leveraging Microsoft technologies, including Power Apps, Power Automate, and SharePoint, 
        the application automates workflows and facilitates seamless communication through Outlook and Teams.
    </p>,
]
