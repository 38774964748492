import React from "react";
import "./AboutSection.css";
import Button from "../../../../components/button/Button";

const AboutSection = () => {

    const handleDownload = (url) => {
        window.open(url, '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:yelyzavetakhmyz@gmail.com'; 
    };

    return (
        <div className="main-wrapper">
            <div className="about-section-overlap">
                <div className="about-section-container">
                    <div className="image-section">
                        <img src="/assets/pages/about-page/about-avatar.svg" alt="Avatar"/>
                    </div>
                    <div className="info-container">
                        <div className="text-container">
                            <h1>
                                About me
                            </h1>
                            <p>
                                I am a recent Computer Science graduate from Ukraine's top technical university, now based in Myrtle Beach, SC.
                                <br/><br/>
                                With a strong academic foundation and a passion for building digital solutions, I enjoy creating web applications that deliver impactful user experiences and am highly motivated to continuously develop new skills.
                                <br/><br/>
                                To further this goal, I am seeking an opportunity to gain real-world experience that will allow me to refine my skills. I am eager to contribute to innovative projects, collaborate with a dynamic team, and tackle new challenges that will help me grow as a developer.
                            </p>
                        </div>
                        <div className="button-container">
                            <Button onClick={() => handleDownload('/diploma.png')} text="Diploma" className="primary-button" ></Button>
                            <Button onClick={handleEmailClick} text="Contact" className="primary-button" ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;