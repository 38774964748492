export const ktsOverviewSection = [
    <h2>Overview</h2>,
    <p>
        <br/><br/>
        In today's fast-paced educational environment, students and faculty often seek convenience in every aspect of their daily lives, including dining. The campus lunch reservation system has been developed with the purpose of enhancing the dining experience by allowing users to reserve their meals in advance. This not only streamlines the process of ordering food but also helps manage meal preparations effectively.
        <br/><br/>
        As a contributor to the UI/UX design of this project, I had the opportunity to focus on creating an engaging and user-friendly experience. Let’s take a closer look at the key elements of the design that make our system user-friendly and engaging.
    </p>,
]

export const ktsFontSection = [
    <h2>Font</h2>,
    <p>
        <br/><br/>
        The font choice is clear and legible. 
        Montserrat offers multiple weights, allowing for the creation of a cohesive typographic hierarchy. 
        Its letterforms are well-spaced and legible, even at smaller sizes. 
        The font's modern and professional aesthetic makes it an excellent choice for the system.
    </p>,
    <img src={"/assets/projects/content/kts-eats/font.png"} alt="Font" loading="lazy"></img>,
]

export const ktsColorSection = [
    <h2>Color Pallete</h2>,
    <p>
        <br/><br/>
        The app's color palette was designed to adhere to the school's official colors:
    </p>,
    <p className="list-style">
        #1E1433: a rich, deep blue
    </p>,
    <p className="list-style">
        #FFC014: a vibrant golden-yellow 
    </p>,
    <p className="list-style">
        #FFFFFF: crisp white 
    </p>,
    <p>
        To enhance usability and visual balance, a light blue (#E9ECF4) was introduced to the palette. This calming tone complements the boldness of the primary colors while serving as a background.
    </p>,
     <img src={"/assets/projects/content/kts-eats/color-pallete.png"} alt="Color Pallete" loading="lazy"></img>,
    <p>
        <br/><br/><br/>
        When selecting a color palette, ensuring high contrast between shades is crucial for readability and usability. Text should be easy to read without strain, and distinct components should be clearly identifiable.
    </p>,
    <img src={"/assets/projects/content/kts-eats/color-pallete-1.png"} alt="Color Pallete" loading="lazy"></img>,
    <img src={"/assets/projects/content/kts-eats/color-pallete-2.png"} alt="Color Pallete"loading="lazy"></img>,
    <p>
        <br/><br/>
        During the contrast analysis of the selected color pallete, it was found out that the following combinations meet high-contrast accessibility standards for text:
    </p>,
    <p className="list-style">
        dark blue background - yellow, white and light blue text
    </p>,
    <p className="list-style">
        white background - dark blue text
    </p>,
    <p className="list-style">
        light blue background - dark blue text
    </p>,
    <p className="list-style">
        yellow background - dark blue text
    </p>,
    <p>
        <br/>
        Other combinations may not be suitable for text but can effectively differentiate visual elements such as buttons or highlighted blocks.
    </p>
]

export const ktsContentOrgSeciton = [
    <h2>Content organization</h2>,
    <p>
        <br/><br/>
        The content is thoughtfully structured to fit entirely on a single screen, and it's done on purpose to eliminate the need for scrolling. 
        <br/><br/>
        By presenting all essential information and interactions within one view, it ensures a seamless and efficient user experience, allowing users to access everything they need immediately.
    </p>
]

export const ktsPagesDesignSection = [
    <h2>Main Pages Design</h2>,
    <h3>Welcome Page</h3>,
    <p>
        <br/>
        The welcome screen design thoughtfully bridges the connection between the school environment and the dining experience. 
        <br/><br/>
        The white background symbolizes a dining table. 
        The centerpiece—a graduation cap integrated with the school logo — represents the academic context, tying the platform to the school identity.
    </p>,
    <img src={"/assets/projects/content/kts-eats/welcome-page.png"} alt="Welcome Page" loading="lazy"></img>,
    <p>
        <br/><br/>
        Aligned with the client requirements, the login process is streamlined and accessible, offering two distinct options:
    </p>,
    <p className="list-style">
        Login through platforms such as Google, Facebook, or Apple for quick and convenient access.
    </p>,
    <p className="list-style">
        Guest login, highlighted for users seeking a seamless entry without account creation.
    </p>,
    <h3>Main Page</h3>,
    <p>
        <br/>
        The Main Page is designed to provide to provide a seamless and personalized experience for users while reserving meal options for the next three days.
    </p>,
    <img src={"/assets/projects/content/kts-eats/main-page.png"} alt="Main Page" loading="lazy"></img>,
    <p>
        <br/><br/>
        Personalized header displays the user's name and profile picture for a customized experience. 
        For guest users, the header remains minimal, without personalization.
        <br/><br/>
        Accoring to client requirements, the booking process is restricted to the next three days for better scheduling. 
        Dates and corresponding days are displayed prominently to provide clear context. 
        Users can easily toggle between available dates to explore different meal plans.
        <br/><br/>
        Separate toggles for Breakfast and Lunch allow users to dynamically switch between meal options. 
        The content updates based on the selected meal type.
        <br/><br/>
        Meals are shown with a vector icon (created from scretch for every dish from menu), portion size and caloric information. 
        The "+" button allows users to get more details - meal description and ingredients.
        <br/><br/>
        Users can view their confirmed reservations, complete with an integrated QR code for streamlined check-in at the venue.
        <br/><br/>
        Users are invited to rate their experience using the designed interactive star-rating system.
        <br/><br/>
        If a reservation expires, the design displays a prominent warning icon along with a clear explanation. 
        Actionable next steps are provided to help users rebook or resolve issues.
    </p>,
    <h3>Party size selector page</h3>,
    <p>
        <br/>
        The Party Size Selector Screen is designed to provide an intuitive experience for users to choose their dining group size.
    </p>,
    <img src={"/assets/projects/content/kts-eats/party-size-page.png"} alt="Party Size Page" loading="lazy"></img>,
    <p>
        <br/><br/>
        A size switcher allows users to select their party size, ranging from 1 to 6. The interface is responsive and ensures ease of use for quick adjustments.
        <br/><br/>
        If logged in as a guest, the screen displays input fields to collect essential user details required for making a reservation. Fields are clear and concise, ensuring a straightforward data entry process.
        <br/><br/>
        If the selected party size exceeds the available capacity, a prominent message appears, notifying the user about the unavailability.
    </p>,
    <h3>
        Checkout page
    </h3>,
    <p>
        <br/>
        The Checkout Screen is designed to present reservation details in a clear and organized format, guiding users through the final steps of the booking process. 
    </p>,
    <img src={"/assets/projects/content/kts-eats/checkout-page.png"} alt="Checkout Page" loading="lazy"></img>,
    <p>
        <br/><br/>
        The information presentation is styled as a digital receipt and contains reservation details and a prominent Checkout Action button to confirm the booking.
        <br/><br/>
        Upon successful payment, a QR Code is displayed for check-in at the venue. A confirmation message informs the user that the QR code has also been sent to their email.
        <br/><br/>
        If the transaction fails, a clear error message is displayed, notifying the user of the issue.
    </p>,
    <h3>Statistics page</h3>,
    <p>
        <br/>
        The Statistics Screen is tailored for staff to monitor and analyze booking trends, providing a comprehensive view of reservation dynamics. 
    </p>,
    <img src={"/assets/projects/content/kts-eats/stat-page.png"} alt="Statistics page" loading="lazy"></img>,
    <p>
        <br/><br/>
        Separate toggles for day, week and month options allow users to dynamically switch between different timeframes. Day section focused insights for current or specific days, week – broader trends across seven days, month – comprehensive view of reservation patterns over a full month.
        <br/><br/>
        For clearity, visually engaging and easy-to-read graphs are designed. They display general number of reservations, expected and actual number of reservations, and rating.
    </p>
]

export const ktsConclustionsSection = [
    <h2>Conclusion</h2>,
    <p>
        <br/><br/>
        The campus lunch reservation system represents a significant advancement in enhancing the dining experience for students and faculty within an educational environment. 
        <br/><br/>
        Through careful attention to UI/UX design principles, the system prioritizes user convenience, accessibility, and engagement.
    </p>
]

export const KTSEatsHeader = "KTS Eats"

export const KTSEatsDescription = "UI/UX design for campus lunch reservation system."

export const KTSEatsAvatarSRC = "/assets/projects/kts-eats.svg"